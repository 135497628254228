'use client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import ErrorIcon from '@/components/icons/ErrorIcon'

import styles from './Alert.module.css'

const AlertRefreshButton = () => {
  const pathname = usePathname()

  return (
    <Link
      href={pathname}
      className={styles.alertButton}
      onClick={(e) => {
        e.preventDefault()

        // router.refresh was not working for some reason
        // @ts-expect-error firefox accepts "true" to clear the cache
        window.location.reload(true)
      }}
      aria-label="Refresh the page"
      title="Refresh the page"
    >
      <ErrorIcon className={styles.icon} width="2rem" height="2rem" />
    </Link>
  )
}

export default AlertRefreshButton
