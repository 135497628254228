'use client'

import AlertContainer from '@/components/atoms/Alert/AlertContainer'
import AlertDescription from '@/components/atoms/Alert/AlertDescription'
import AlertTitle from '@/components/atoms/Alert/AlertTitle'
import ErrorRefreshButton from '@/components/atoms/Alert/ErrorRefreshButton'

const GlobalError = () => {
  return (
    <AlertContainer height="100vh">
      <ErrorRefreshButton />

      <AlertTitle>There was an unexpected error</AlertTitle>
      <AlertDescription>Click here to try again</AlertDescription>
    </AlertContainer>
  )
}

export default GlobalError
