import { SVGProps } from 'react'

import styles from '@/app/styles.json'

export type IconProps = SVGProps<SVGSVGElement> & {
  size?: number | string
  secondColor?: string
  hasOverlay?: boolean
}

const Icon = ({ size = 24, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg
      width={props.width ?? size}
      height={props.height ?? size}
      viewBox={props.viewBox ?? '0 0 24 24'}
      fill={color ?? styles.color['neutral-0']}
      color={color ?? styles.color['neutral-0']}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      {props.children}
    </svg>
  )
}

export default Icon
