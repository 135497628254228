import classNames from 'classnames'

import { BoxProps } from '@/components/atoms/Box/Box'

import styles from './Alert.module.css'

export const AlertTitle = ({ className, children, ...props }: BoxProps) => {
  return (
    <span className={classNames(styles.alertTitle, className)} {...props}>
      {children}
    </span>
  )
}

export default AlertTitle
