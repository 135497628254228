import classNames from 'classnames'

import { BoxProps } from '@/components/atoms/Box/Box'

import styles from './Alert.module.css'

type AlertContainerProps = BoxProps & {
  height?: string
}

const AlertContainer = ({
  className,
  children,
  height,
  style,
  ...props
}: AlertContainerProps) => {
  return (
    <div
      role="alert"
      className={classNames(className, styles.alertContainer)}
      style={{
        height: height,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export default AlertContainer
