import classNames from 'classnames'

import { BoxProps } from '@/components/atoms/Box/Box'

import styles from './Alert.module.css'

export const AlertDescription = ({
  className,
  children,
  ...props
}: BoxProps) => {
  return (
    <span className={classNames(className, styles.alertDescription)} {...props}>
      {children}
    </span>
  )
}

export default AlertDescription
